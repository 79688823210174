import { Component, OnInit, ViewChildren, OnDestroy, Input, QueryList, ElementRef } from '@angular/core';
import { PagedataService } from '../services/pagedata.service';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { NavigationController } from '../services/navigationController';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RequestParamsService } from '../services/requestParams.service';
import { BlockData } from '../interfaces/blockData.interface';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { Subscription } from 'rxjs';
import { AccountService } from '../services/account.service';
import { WindowService } from '../services/windowService';
import { NavigateService } from '../services/navigate.service';
import { BlockService } from '../services/block.service';

@Component({
  selector: 'app-block',
  templateUrl: '../block/block.component.html',
  styleUrls: ['../block/block.component.scss']
})
export class BlockComponent implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() blockData: any;
  @Input() position: string;
  @Input() isUseCompanyMenu: boolean = false;
  @ViewChildren('videoBlock') videoBlockElement: QueryList<ElementRef>;

  public isStandAlone: boolean = false;
  public sideMenuSelectedCategoryId = -1; // 選択中カテゴリid -1は全表示
  public isWideCategorySelector: boolean = false; // カテゴリ選択サイドバーの幅
  public seeMoreButtonRequiredStatus = {};
  private resizeSubscription: Subscription;
  private isCompany: boolean = false;
  private salonAccountName: string = null;

  constructor(
    public pds: PagedataService,
    private navigationController: NavigationController,
    private router: Router,
    private requestParamsService: RequestParamsService,
    public shopDetailModalService: ShopDetailModalService,
    private sanitizer: DomSanitizer,
    private platform: Platform,
    public accountService: AccountService,
    private windowService: WindowService,
    private navigateService: NavigateService,
    public blockService: BlockService
  ) {}

  async ngOnInit() {
    this.resizeSubscription = this.platform.resize.subscribe(() => {
      this.seeMoreButtonRequiredStatus = {};
    });
    this.isCompany = this.pds.getIsCompany();
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  getImageIframeHTML(imageIframe: string) {
    let imageIframeHTML: SafeHtml;
    imageIframeHTML = this.sanitizer.bypassSecurityTrustHtml(imageIframe);
    return imageIframeHTML;
  }

  // 外部リンクボタンブロック
  async clickUrlButtonBlock(buttonBlock: HTMLElement, block: BlockData) {
    // 登録内容からURLを生成する
    let urlString = '';
    if (Number(block.buttonUrlHeadKind) === 1) {
      urlString += 'http://';
    } else if (Number(block.buttonUrlHeadKind) === 2) {
      urlString += 'https://';
    }
    urlString += block.buttonUrlBody;
    // 外部リンク遷移を行う
    await this.navigateService.navigateExternalUrl(urlString, block.buttonIsOpenNewTab);
  }

  // 内部リンクボタンブロック
  async clickInternalUrlButtonBlock(buttonBlock: HTMLElement, block: BlockData) {
    var $companyContents = buttonBlock.closest('.companyContents');
    if ($companyContents) {
      await this.navigateService.navigateUrlForCompanyContents(
        block.buttonInternalUrl,
        block.buttonIsOpenNewTab,
        $companyContents.attributes['data-salon_id'].value
      );
    } else {
      // 内部リンク遷移を行う
      this.navigateService.navigateInternalUrl(block.buttonInternalUrl, block.buttonIsOpenNewTab);
    }
  }

  // 店舗リンククリック関数
  clickShopLinkBlock(link: string) {
    // linkで渡ってきたパラメータを分解する
    let linkParams = link.split('&');
    let params: { shopDetail: string; page: string } = {
      shopDetail: null,
      page: null
    };
    for (var linkParam of linkParams) {
      (<any>params)[linkParam.split('=')[0]] = linkParam.split('=')[1];
    }
    // 分解したパラメータをセットして、店舗詳細モーダルを起動する
    this.shopDetailModalService.openShopDetailModal(params['shopDetail'], params['page'], { pageName: params['page'] });
  }

  // ２列レイアウトの列番号配列を生成
  arrayColumnNumList(columnNum: string): any[] {
    let maxNum = Number(columnNum.split('-')[0]);
    let numList = [];
    for (let i = 1; i <= maxNum; i++) {
      numList.push(maxNum + '-' + i);
    }
    return numList;
  }

  // 動画ブロックの再セット（再生中の動画を一旦止めて、同じ要素をセットする）
  resetVideoBlock() {
    let videoBlockList = this.videoBlockElement.toArray();
    if (videoBlockList.length > 0) {
      for (let videoBlock of videoBlockList) {
        videoBlock.nativeElement.innerHTML = videoBlock.nativeElement.innerHTML;
      }
    }
  }

  // カテゴリ選択絞り込み表示
  selectCategory(id: number): void {
    this.sideMenuSelectedCategoryId = id;
    // メニュートップまでスクロール
    const scrollTargetElem: HTMLIonContentElement = document.querySelector('ion-content');
    const scrollToPositionElem: HTMLElement = document.querySelector('.menu_container');
    const headerHeight = 80; // SPサイズで算出
    if (scrollTargetElem && scrollToPositionElem) {
      const toY = scrollToPositionElem.offsetTop - headerHeight;
      scrollTargetElem.scrollToPoint(0, toY, 200);
    }
  }

  // カテゴリセレクタサイドバーの開閉
  toggleCategorySelector(): void {
    this.isWideCategorySelector = !this.isWideCategorySelector;
  }

  // Googleクチコミの「もっとみる」ボタンクリック時
  clickGoogleReviewMore(commentElem: HTMLElement) {
    commentElem.classList.remove('ellipsis', 'checkEllipsis');
  }
}
