import { Injectable } from '@angular/core';
import { IonContent } from '@ionic/angular';

import { PagedataService } from '../services/pagedata.service';
import { Router, NavigationExtras } from '@angular/router';
import { NavigationController } from './navigationController';
import { RequestParamsService } from './requestParams.service';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import { LayoutData } from '../interfaces/layoutData.interface';
import { initLayoutData } from '../initData/initData';
import { ShopDetailModalService } from './shopDetailModal.service';
import { AccountService } from './account.service';
import { WindowService } from './windowService';
import { PagedataApiService } from './pagedataApi.service';
import { AppComponent } from '../app.component';

@Injectable()
export class NavigateService {
  public shopName: string;
  public isStandAlone: boolean = false;
  public sideMenuSelectedCategoryId = -1; // 選択中カテゴリid -1は全表示
  public isWideCategorySelector: boolean = false; // カテゴリ選択サイドバーの幅
  public seeMoreButtonRequiredStatus = {};
  public salonAccountName: string = null;
  public layoutData: LayoutData = initLayoutData;
  public pwaEnv: string = 'production';
  navigationExtras: NavigationExtras = {
    queryParams: { p: 1 }
  };

  constructor(
    private pds: PagedataService,
    private pagedataApiService: PagedataApiService,
    private router: Router,
    private navigationController: NavigationController,
    private requestParamsService: RequestParamsService,
    private accountService: AccountService,
    private windowService: WindowService,
    private shopDetailModalService: ShopDetailModalService
  ) {
    this.isStandAlone = this.pds.getIsStandAloneMode();
  }

  // 外部リンクを開く
  public async navigateExternalUrl(urlString: string, isOpenNewTab: boolean) {
    const shopDetailModalShopName = this.shopDetailModalService.shopName;

    // URL文字列から、URLに変換してrequestParamsを取得する
    const url = new URL(urlString);
    let requestParams = this.requestParamsService.getRequestParams(url);

    // URLがPWAの独自ドメインかどうか判断する
    const domainList = this.pds.ownDomainList;
    const isSalonExistInDomain =
      domainList.find((domain) => domain.ownDomain === requestParams.salonOrDomainName) || null;
    const ownDomainSalon =
      isSalonExistInDomain !== null && isSalonExistInDomain.accountName ? isSalonExistInDomain : null;
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    this.salonAccountName = !!accountData.accountName ? accountData.accountName : null;

    // 独自ドメインの場合は独自ドメインのaccountNameを取得、そうでない場合はリクエストパラメータのaccountNameをセット
    const urlAccountName = ownDomainSalon ? ownDomainSalon.accountName : requestParams.salonOrDomainName;
    // クリックしたURLのaccountNameに対して、shopDetailが有効かどうかを判別する（ページaccountNameが本部、かつその本部に紐づくshopDetailである場合のみ有効と判断）
    let shopDetail = requestParams.shopDetail === '' ? null : requestParams.shopDetail;
    const isActiveShopDetail = await this.pds.checkShopdetailRelSalonForUrl(urlAccountName, shopDetail);
    // PWAのページかどうかを判断する（pwa、pwah以外のドメインで、独自ドメインリストに存在しない場合は外部のページと判断。それ以外はPWAのページと判断）
    const isPwaPage = !(requestParams.isOutExternalPage && isSalonExistInDomain === null);
    if (isPwaPage) {
      // PWAのページの場合
      if (requestParams.shopDetail !== null && !isActiveShopDetail) {
        // requestParams.shopDetailがnull以外（空文字を通したい）で、shopDetailが無効（URLのaccountNameに対して紐づく店舗として判断されなかった場合）の場合
        // 無効なURLと判断し、/homeに遷移する
        urlString = url.origin + '/home';
        const newUrl = new URL(urlString);
        requestParams = this.requestParamsService.getRequestParams(newUrl);
      }
    }
    // 現在開いているページのドメインアカウントと、URLのドメインアカウントが同じかどうかを判別する
    const isSameDomain = this.salonAccountName === urlAccountName;
    // 店舗詳細モーダルで開いている店舗アカウントと、URLのドメインアカウントが同じかどうかを判別する
    const isSameAccount = shopDetailModalShopName === urlAccountName;
    // 店舗詳細モーダルで開いている店舗アカウントと、URLの店舗詳細アカウントが同じかどうかを判別する
    const isSameShopDetail = shopDetailModalShopName === requestParams.shopDetail;

    let linkAction: string = null;
    if (this.shopDetailModalService.isShopDetailNav) {
      // 店舗詳細モーダルで開いている場合
      if (accountData.isCompany) {
        // 本部ページとしてアクセスしている場合
        if (isSameDomain) {
          // 同一ドメインの場合
          if (isSameShopDetail) {
            // shopDetailが同じ場合
            // 店舗詳細モーダル内のページ追加を行う
            linkAction = 'pushPage';
          } else {
            // shopDetailが違う場合
            // 一旦aタグの設定通りに遷移する
          }
        } else {
          // 同一ドメインではない場合
          if (isSameAccount) {
            // 店舗詳細モーダルで開いている店舗アカウントと、ドメインアカウントが同じ場合');
            // アドバンス店舗でサイト内ページをセットした外部リンクをクリックしているので、ページ追加を行う
            linkAction = 'pushPage';
          } else {
            // 店舗詳細モーダルで開いている店舗アカウントと、ドメインアカウントが違う場合
            // aタグの設定通りに遷移する
          }
        }
      } else {
        // 店舗ページとしてアクセスしている場合（エンタープライズアドバンス店舗で店舗詳細モーダルを開いている場合）
        if (isSameDomain) {
          // 同一ドメインの場合
          // 店舗ページから店舗詳細モーダル内で、店舗ページへのリンクをクリックした時に通る
          // aタグの設定通りに遷移する
        } else {
          // 同一ドメインではない場合
          // 表示しているアドバンス店舗をhttps://fukadu98.pwa.1cs-staging.jpとし
          if (isSameAccount) {
            // 店舗詳細モーダルで開いている店舗アカウントと、ドメインアカウントが同じ場合
            if (requestParams.shopDetail) {
              // URLにshopDetailがセットされている場合は、無効なURLとして扱う
              // 【このケースでURLを無効とする考え】
              // isSameDomain：falseで、現在開いているページのドメインアカウントと、URLのドメインアカウントが違う。現在開いているアドバンス店舗とは別であり
              // isSameAccount：trueで、店舗詳細モーダルで開いている店舗アカウントと、URLのドメインアカウントが同じ。本部ドメインを使用するエンタープライズ店舗の場合はfalseなので、紐づく別のアドバンス店舗であると判断
              // 紐づくアドバンス店舗において、shopDetailがセットされることはURL的にありえないものとし、紐づくアドバンス店舗にshopDetailがセットされている場合はURLを無効にする
              // 考えられるパターン）fukadu99の店舗詳細モーダルを開いていてhttps://cs-pwa.com/home?shopDetail=fukadu97（意図しないURL）をクリック
              linkAction = 'nonActiveUrl';
            } else {
              // URLにshopDetailがセットされていない場合は、ページ追加を行う
              // 考えられるパターン）fukadu99の店舗詳細モーダルを開いていてhttps://cs-pwa.com/shop（正常なURL）をクリック
              linkAction = 'pushPage';
            }
          } else {
            // 店舗詳細モーダルで開いている店舗アカウントと、ドメインアカウントが違う場合
            if (isSameShopDetail) {
              // 考えられるパターン）fukadu97の店舗詳細モーダルを開いていてhttps://hcs-pwa.com/home?shopDetail=fukadu97&page=shopをクリック
              linkAction = 'pushPage';
            } else {
              // 店舗詳細モーダルで開いている店舗アカウントと、ドメインアカウントが違う場合
              // aタグの設定通りに遷移する
              // 考えられるパターン）fukadu97の店舗詳細モーダルを開いていてhttps://hcs-pwa.comをクリック
              // 考えられるパターン）https://1cs.jp/doc/function/pwa、外部のページをクリック
            }
          }
        }
      }
    } else {
      // 店舗詳細モーダルで開いていない場合
      if (isSameDomain) {
        // 同一ドメインの場合
        if (requestParams.shopDetail) {
          // shopDetail有：店舗詳細モーダルを開く
          linkAction = 'openShopDetailModal';
        } else {
          // shopDetail無：ページ遷移の分岐を行う
          linkAction = 'navigateByPwaUrl';
        }
      } else {
        // 同一ドメインでない場合、現在開いているページ外のページと判断する
        // タグ設定通りに遷移する
      }
    }

    // 店舗名は詳細店舗名（shopDetail）がセットされている場合はshopDetail、セットされていない場合はurlAccountName
    let shopName = requestParams.shopDetail ? requestParams.shopDetail : urlAccountName;
    // ページ名はパラメータにpageの指定がある場合はpageをセット、指定がなければpageNameをセット
    let pageName = requestParams.params.page ? requestParams.params.page : requestParams.pageName.replace('/', '');
    pageName = pageName === '' ? 'home' : pageName;

    switch (linkAction) {
      case 'openShopDetailModal':
        // 店舗詳細モーダルを開く
        this.shopDetailModalService.openShopDetailModal(shopName, pageName, requestParams.params);
        break;
      case 'pushPage':
        // 店舗詳細モーダル内でページ追加を行う
        this.shopDetailModalService.pushPage(shopName, pageName, requestParams.params);
        break;
      case 'navigateByPwaUrl':
        // PWA内のページ遷移をrouterで行う
        // isOpenNewTabActionの初期値をfalseに設定し、動作の判定を行う
        let isOpenNewTabAction = false;
        // アプリ化もしくはプレビューの場合は、isOpenNewTabActionはfalseのまま
        // アプリ化ではなく、プレビューでもない場合は、isOpenNewTabActionにisOpenNewTabをセット（「新しいタブで開く」の設定を反映する）
        if (!this.isStandAlone && !this.layoutData.isPreview) {
          isOpenNewTabAction = isOpenNewTab;
        }
        if (isOpenNewTabAction) {
          // 新しいタブで開く
          this.windowService.openWindow(urlString);
        } else {
          // 現在のタブで設定したURLへrouterで遷移する
          this.router.navigate([pageName], { queryParams: requestParams.params });
          this.navigationController.setClickedRouterLink(true);
        }
        break;
      default:
        if (this.isStandAlone && isPwaPage) {
          // アプリ化の状態で、PWAのページへ遷移する場合
          if (isSameDomain) {
            // ドメインが一致する場合はアプリの再読み込みが発生するので、reload=trueを追加する
            urlString += urlString.indexOf('?') === -1 ? '?reload=true' : '&reload=true';
          } else {
            // ドメインが一致しない場合はアプリ内ブラウザで開くので、view_mode=browserを追加する
            urlString += urlString.indexOf('?') === -1 ? '?view_mode=browser' : '&view_mode=browser';
          }
        }
        // 外部のページへ、タグ設定通りに遷移する
        if (isOpenNewTab) {
          // 新しいタブで開く
          this.windowService.openWindow(urlString);
        } else {
          // 現在のタブで遷移する
          window.location.href = urlString;
        }
        break;
    }
  }

  // 内部リンクを開く
  public navigateInternalUrl(urlString: string, isOpenNewTab: boolean): void {
    urlString = urlString.split('?')[0];
    if (this.shopDetailModalService.isShopDetailNav) {
      // 店舗詳細モーダルを開いている場合は、店舗詳細モーダル内でページ追加を行う
      const shopDetailModalShopName = this.shopDetailModalService.shopName;
      this.shopDetailModalService.pushPage(shopDetailModalShopName, urlString.replace('/', ''));
    } else {
      // 店舗詳細モーダルを開いていない場合は、ページ遷移を行う

      // isOpenNewTabActionの初期値をfalseに設定し、動作の判定を行う
      let isOpenNewTabAction = false;
      // アプリ化もしくはプレビューの場合は、isOpenNewTabActionはfalseのまま
      // アプリ化ではなく、プレビューでもない場合は、isOpenNewTabActionにisOpenNewTabをセット（「新しいタブで開く」の設定を反映する）
      if (!this.isStandAlone && !this.layoutData.isPreview) {
        isOpenNewTabAction = isOpenNewTab;
      }
      if (isOpenNewTabAction) {
        // 新しいタブで開く
        this.windowService.openWindow(urlString);
      } else {
        // 現在のタブで設定したURLへrouterで遷移する
        this.router.navigateByUrl(urlString);
        this.navigationController.setClickedRouterLink(true);
      }
    }
  }

  // リンクを外部リンクとして開く（店舗側で本部内容を表示している場合に使用）
  public async navigateUrlForCompanyContents(urlString: string, isOpenNewTab: boolean, salonId: number) {
    // 本部の内容に合わせてURLを生成するためのデータを取得
    let data = await this.pds.getDataForLink(salonId);
    this.pwaEnv = await this.pagedataApiService.getPwaEnv();

    // URLを生成する
    var url = '';
    url += data['local'] ? 'http://' : 'https://';
    if (data['salonData']['ownDomain'] !== null) {
      // 独自ドメイン利用の場合は独自ドメインをセット
      url += data['salonData']['ownDomain'];
    } else {
      // 独自ドメイン利用でない場合は、accountNameでドメインを生成
      url += data['salonData']['accountName'];
      if (data['local']) {
        url += AppComponent.LOCAL_DOMAIN;
      } else {
        url +=
          this.pwaEnv === 'production' ? AppComponent.PRODUCTION_COMPANY_DOMAIN : AppComponent.STAGING_COMPANY_DOMAIN;
      }
    }
    // 内部リンクの内容を追加
    url += urlString;
    // ローカルの場合はcompany=trueを追加
    if (data['local']) {
      url += url.indexOf('?') === -1 ? '?company=true' : '&company=true';
    }

    // アプリ化の場合
    if (this.isStandAlone) {
      let requestParams = this.requestParamsService.getRequestParams(new URL(url));

      // URLがPWAの独自ドメインかどうか判断する
      const domainList = this.pds.ownDomainList;
      const isSalonExistInDomain =
        domainList.find((domain) => domain.ownDomain === requestParams.salonOrDomainName) || null;
      const ownDomainSalon =
        isSalonExistInDomain !== null && isSalonExistInDomain.accountName ? isSalonExistInDomain : null;
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      this.salonAccountName = !!accountData.accountName ? accountData.accountName : null;

      // 独自ドメインの場合は独自ドメインのaccountNameを取得、そうでない場合はリクエストパラメータのaccountNameをセット
      const urlAccountName = ownDomainSalon ? ownDomainSalon.accountName : requestParams.salonOrDomainName;
      // 現在開いているページのドメインアカウントと、URLのドメインアカウントが同じかどうかを判別する
      const isSameDomain = this.salonAccountName === urlAccountName;

      // アプリ化の状態で、PWAのページへ遷移する場合
      if (isSameDomain) {
        // ドメインが一致する場合はアプリの再読み込みが発生するので、reload=trueを追加する
        url += url.indexOf('?') === -1 ? '?reload=true' : '&reload=true';
      } else {
        // ドメインが一致しない場合はアプリ内ブラウザで開くので、view_mode=browserを追加する
        url += url.indexOf('?') === -1 ? '?view_mode=browser' : '&view_mode=browser';
      }
    }

    // 外部のページへ、タグ設定通りに遷移する
    if (isOpenNewTab) {
      // 新しいタブで開く
      this.windowService.openWindow(url);
    } else {
      // 現在のタブで遷移する
      window.location.href = url;
    }
  }
}
