<ion-content
  *ngIf="(pageData.isDisplay && !isShowPrivateMessage) || !isDisplayPrevPage"
  class="overflow_scroll"
  id="{{'free_window_top_' + pageData.id}}"
  name="blur_aria"
  [class.blur_active]="(!isShopDetailNav && blurActiveFlgFromHeader) || (isShopDetailNav && blurActiveModalFlgFromHeader)"
  [class.__modalStyle__]="isShopDetailNav"
  [scrollEvents]="true"
  [attr.data-page_title]="pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  (ionScroll)="mfbc.scrollEvent(content)"
>
  <main class="main mainFreeSimple content_top rmMbLastContentsGroup">
    <!-- フリーシンプルページ　見出し -->
    <section class="freeSimpleHeading sec">
      <div class="breadcrumbs" *ngIf="pageData.isDisplayBreadcrumbs; else elseIsBreadcurmbs">
        <ng-container *ngFor="let breadcrumbsData of pageData.breadcrumbsList">
          <span
            class="breadcrumbs_item home"
            *ngIf="breadcrumbsData.url == 'home'; else elseBreadcurmbsContent"
            (click)="clickLink( 'home' )"
            [class.__modalStyle__]="isShopDetailNav"
          ></span>
          <ng-template #elseBreadcurmbsContent>
            <label class="breadcrumbs_arrow" [class.__modalStyle__]="isShopDetailNav"> > </label>
            <span
              class="breadcrumbs_item"
              (click)="clickLink( breadcrumbsData.url, { pageName: breadcrumbsData.url } )"
              [class.__modalStyle__]="isShopDetailNav"
              >{{breadcrumbsData.title}}</span
            >
          </ng-template>
        </ng-container>
      </div>
      <ng-template #elseIsBreadcurmbs>
        <span
          href="javascript:void(0);"
          class="freeSimpleHeading__backLink"
          (click)="navigationController.goBack()"
          [class.__modalStyle__]="isShopDetailNav"
          ><</span
        >
      </ng-template>

      <div *ngIf="!isDisplayPrevPage" class="hiddenPageAlert">
        <div *ngIf="isDisplayPubPage" class="hiddenPageAlert__title__ForSave">このページは非表示予定です</div>
        <div *ngIf="!isDisplayPubPage" class="hiddenPageAlert__title__ForPublic">このページは現在非表示中です</div>
      </div>

      <div class="freeSimpleHeading_container secContainer">
        <h2 class="freeSimpleHeading__heading secHeading rmMbTarget" [class.__modalStyle__]="isShopDetailNav">
          {{pageData.title}}
        </h2>
      </div>

      <div
        class="freeSimpleBlock blockArea"
        *ngIf="blockData[pageData.sideMenuData.name + '__titleBlock'] && blockData[pageData.sideMenuData.name + '__titleBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData[pageData.sideMenuData.name + '__titleBlock']"
          [position]="pageData.sideMenuData.name + '__titleBlock'"
          #appBlock
        ></app-block>
      </div>
      <!-- /.freeSimpleHeading_container -->
    </section>
    <!-- /.freeSimpleHeading -->

    <!-- フリーシンプルページ　コンテンツ -->
    <section
      class="freeSimpleContent sec"
      *ngIf="((pageData.image || pageData.imageIframe ) && pageData.isDisplayImage) || (blockData[pageData.sideMenuData.name + '__visualBlock'] && blockData[pageData.sideMenuData.name + '__visualBlock'].length > 0)"
    >
      <div class="freeSimpleContent_container secContainer">
        <!-- 画像 -->
        <div
          *ngIf="(pageData.image || pageData.imageIframe ) && pageData.isDisplayImage"
          class="freeSimpleContent__eyecatch secContent"
        >
          <div class="freeSimpleContent__eyecatch__inner rmMbTarget">
            <picture *ngIf="pageData.imageDisplayType != 4">
              <source *ngIf="pageData.webpImage != null" type="image/webp" [srcset]="pageData.webpImage" />
              <img
                class="freeSimpleContent__eyecatch__inner__img"
                [src]="pageData.image"
                [alt]="pageData.imageAlt ? pageData.imageAlt : pageData.altSalonName + 'の' + pageData.title + 'の写真'"
                [class.width90]="pageData.imageDisplayType == 2"
                [class.width50]="pageData.imageDisplayType == 3"
              />
            </picture>
            <div
              class="freeSimpleContent__eyecatch__video"
              *ngIf="pageData.imageDisplayType == 4"
              [innerHTML]="pageData.imageHtml"
              [ngClass]="['iframe_free_' + pageData.id]"
              #videoIframeContent
            ></div>
          </div>
        </div>
        <!-- /.freeSimpleContent__eyecatch -->
      </div>
      <div
        class="freeSimpleBlock blockArea lastMarginBottomTrim"
        *ngIf="blockData[pageData.sideMenuData.name + '__visualBlock'] && blockData[pageData.sideMenuData.name + '__visualBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData[pageData.sideMenuData.name + '__visualBlock']"
          [position]="pageData.sideMenuData.name + '__visualBlock'"
          #appBlock
        ></app-block>
      </div>
      <!-- /.freeSimpleContent_container -->
    </section>

    <!-- フリーシンプルページ　コンテンツ -->
    <section
      class="freeSimpleContent sec"
      *ngIf="(blockData[pageData.sideMenuData.name + '__bodyBlock'] && blockData[pageData.sideMenuData.name + '__bodyBlock'].length > 0) || pageData.body"
    >
      <div class="freeSimpleContent_container secContainer">
        <!-- 本文 -->
        <p
          class="freeSimpleContent__detail"
          [class.__modalStyle__]="isShopDetailNav"
          [innerHTML]="pageData.body | htmlSanitize"
        ></p>
      </div>
      <div
        class="freeSimpleBlock blockArea"
        *ngIf="blockData[pageData.sideMenuData.name + '__bodyBlock'] && blockData[pageData.sideMenuData.name + '__bodyBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData[pageData.sideMenuData.name + '__bodyBlock']"
          [position]="pageData.sideMenuData.name + '__bodyBlock'"
          #appBlock
        ></app-block>
      </div>
      <!-- /.freeSimpleContent_container -->
    </section>
    <!-- /.freeSimpleContent -->
  </main>
  <!-- /.mainFreeSimple -->

  <!-- フッター -->
  <app-footer [shopName]="this.shopName" [isShopDetailNav]="isShopDetailNav" class="appFooter"></app-footer>
</ion-content>
<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="applike"
  [blurActiveFlg]="blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
